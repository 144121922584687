.components_menu {
	position: relative;
	display: block;
	width: 350px;
	max-width: 95%;
	margin: auto;
	background-color: $Light;
	border-radius: 0.3em;
	border: 1px solid $ExtraLight;
	box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
	overflow: hidden;
	z-index: 20;

	h2 {
		display: block;
		text-align: center;
		color: $mainColor;
		margin: 1em;
	}

	.links-container {
		margin-bottom: 1.2em;
		span {
			@include font-size(22px, 28px);
			display: block;
			width: 80%;
			margin: 0.7em auto;
			text-align: center;
			color: $Dark;
			border-radius: 0.5em;
		}

		span.active {
			color: $Light;
			background-color: $mainColor;
		}
	}

	.icons-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 80%;
		margin: 1em auto;
		padding: 0.3em;
		border: 2px solid $mainColor;
		border-radius: 1em;
	}
}
