// RESPONSIVE (VIEWPORT SIZES)
$minWidth: 600px !default;
$maxWidth: 1600px !default;

// Basic Colors
$Dark: #222831; // My Black: rgb(34,40,49)
$MedDark: #393e46; // My Dark Gray: rgb(57,62,70)
$MedLight: #8e8e90; // My Gray: Silver: rgb(142,142,144)
$Light: #f7f7f7; // My White:(247,247,247)
$Danger: rgb(255, 59, 48); // Apple System Light Red
$Info: rgb(52, 199, 89); // Apple System Light Green
$Alert: rgb(255, 149, 0); // Apple System Light Orange
$ExtraLight: rgba(1, 1, 1, 0.1);

// Main Color
$mainDark: rgb(20, 100, 255);
$mainMDark: rgb(10, 132, 255); // Apple System Dark Blue
$mainColor: rgb(0, 122, 255); // Apple System Light Blue #007AFF
$mainLight: rgb(100, 210, 255); // Apple System Dark Teal

// Navbar
$navbarColor: rgba(28, 28, 30, 0.97);

// Font-Family
$font-family: 'Arial Narrow', 'Arial', 'sans-serif';
$font-family-title: 'Optima', 'Arial', 'sans-serif';
$logo: 'Roboto Flex', sans-serif;
$navbar: 'Roboto Flex', sans-serif;
$title: 'Roboto Flex', sans-serif;
$subTitle: 'Roboto Flex', sans-serif;
$subTitle2: 'Poppins', sans-serif;
$body: 'Poppins', sans-serif;
$buttons: 'Poppins', sans-serif;

// COMPONENTS
$border-rad: 8px;

// SHADOWS
$shadow: 0 0 1em rgba(0, 0, 0, 0.25);
$card-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
$card-shadow-h: 0 12px 16px rgba(0, 0, 0, 0.8);
$btn-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
$btn-shadow-click: 2px 2px 10px rgba(0, 0, 0, 0.5);
$text-shadow: 1px 1px rgba(0, 0, 0, 0.2);

// Colors
$Amazon: #ff9900;
$Facebook: #3b5998;
$Github: #000;
$Instagram: #dd2a7b;
$Linkedin: #0077b5;
$Telegram: #0088cc;
$Twitter: #1da1f2;
$Whatsapp: #00bb2d;
$Print: 'red';