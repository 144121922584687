.card-project-container{
    @include font-size(12px, 18px);
    position: relative;
    width: 100%;
    max-width: 800px;
    color: $MedLight;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    margin: 1em auto;
    border: 2px solid $Light;

    .card-project-img{
        display: grid;
		place-items: center;
        width: 39%;
        height: 100%;
        min-height: 300px;
        margin: 0;
        border: 2px solid $Light;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
			pointer-events: none;
        }

        span.number{
            position: absolute;
            top: 3px;
            left: 3px;
            font-size: 1.5em;
            color: $Dark;
            padding: 0.2em;
            background: $Light;
            border-radius: 0.1em;
        }
    }

    .card-project-data{
        display: block;
        position: relative;
        color: $Light;
        width: 60%;
        margin: 0;
        padding: 0.5em;
        border: 2px solid $Light;
        overflow: hidden;
        
        p{
            margin: 0;
            
            b{
                margin-left: 1em; 
            }
            b.filter{
                display: flex;
                margin-left: 0.5em;
            }
        }
        p.card-data-img{
            width: 98%;
            height: 5em;
            word-wrap: break-word;
            // border: 2px solid red;
        }
    }
}

@media (max-width: 600px){
    .card-project-container{
        flex-direction: column;

        .card-project-img{
            width: 100%

        }

        .card-project-data{
            width: 100%;
        }
    }
}

