// SELECTOR DISPLAY
.display {
	margin-top: 1em;
	margin-bottom: 4em;
	label {
		@include font-size(24px, 30px);
		color: $Light;

		cursor: pointer;
	}
	select {
		@include font-size(20px, 26px);
		color: $Dark;
		cursor: pointer;
		outline: none;
		margin-left: 1em;
		// text-transform: uppercase;
	}
}

// GRID
.gallery-container.grid {
	display: grid;
	gap: 0.1em;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	grid-auto-flow: dense;
}

// MONDRIAN
.gallery-container.mondrian {
	display: grid;
	gap: 0.1em;
	padding: 0 0.1em;
	grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
	grid-auto-flow: dense;

	.image-box:nth-child(3n + 3) {
		grid-row: span 2;
	}

	.image-box:nth-child(8n + 1) {
		grid-column: span 2;
	}

	.image-box:nth-child(16n + 5) {
		grid-column: span 2;
		grid-row: span 2;
	}
}

// FLEX
.gallery-container.flex {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	> .image-box {
		@include size(420px, 400px, 1, 1);
		overflow: hidden;
		border: 1px solid $Dark;
	}
}

// MODAL
.modal {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background-color: rgba(0, 0, 0, 0.2);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.8);
	overflow: hidden;
	z-index: 125;

	&__img {
		position: relative;
		margin: auto;
		padding: 0;
		width: 100%;
		max-height: 90%;
		background-color: $Light;
		overflow-y: auto;
		z-index: 150;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			pointer-events: none;
		}

		.close {
			position: absolute;
			top: 0.5em;
			right: 0.5em;
			cursor: pointer;
			z-index: 200;
		}

		.info {
			position: absolute;
			top: 0.5em;
			left: 0.5em;
			cursor: pointer;
			z-index: 200;
		}

		.edit {
			position: absolute;
			top: 0.5em;
			left: 3em;
			cursor: pointer;
			z-index: 200;
		}

		.delete {
			position: absolute;
			top: 0.5em;
			left: 5.5em;
			cursor: pointer;
			z-index: 200;
		}

		svg {
			@include size(25px, 40px, 1, 1);
			fill: $Alert;
			height: auto;
			border-radius: 50%;
			background-color: $Light;
		}

		.image-info,
		.image-edit,
		.image-delete {
			position: absolute;
			padding: 1em;
			top: 50%;
			left: 50%;
			width: 90%;
			transform: translate(-50%, -50%);
			background-color: rgba(250, 250, 250, 0.95);
			box-shadow: $card-shadow;

			p {
				@include font-size(14px, 28px);
				// font-family: 'Comic Sans MS', sans-serif;
				font-family: 'Handlee', cursive;
				margin: 0;

				b {
					font-family: 'Courier New', Courier, monospace;
				}
			}
		}

		.image-edit {
			border: 2px solid $Dark;

			button {
				margin-top: 1em;
				margin-bottom: 1em;
			}

			.item-content {
				margin: 0.5em 0;
			}
		}

		.image-delete {
			border: 2px solid $Danger;
		}
	}
}

// FOR TABLETS & MODERN MOBILES
@include mQ(768px) {
	// MONDRIAN{
	.gallery-container.mondrian {
		grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	}
}

// FOR DESKTOPS
@include mQ(998px) {
	.gallery-container.grid,
	.gallery-container.flex,
	.gallery-container.mondrian {
		.image-box {
			max-width: 680px;
		}
	}

	// GRID
	.gallery-container.grid {
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		.image-box {
			margin: auto;
		}
	}

	// MONDRIAN{
	.gallery-container.mondrian {
		grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
	}

	// MODAL
	.modal {
		background-color: rgba(255, 255, 255, 0.5);

		&__img {
			height: 90%;
			width: auto;
			overflow: hidden;

			.image-info {
				p {
					margin: 0.5em;
				}
			}
		}

		.close {
			top: 1em;
			right: 1em;
		}

		.info {
			top: 1em;
			left: 1em;
		}
		.edit {
			top: 1em;
			left: 4em;
		}
		.delete {
			top: 1em;
			left: 7em;
		}
	}
}
