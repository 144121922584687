body {
	box-sizing: border-box;
	width: 100%;
	max-width: $maxWidth;
	margin: 0 auto;
	padding: 0;
	height: 100%;
	scroll-behavior: smooth;
	font-family: $font-family;
	background-color: $Dark;

	.general__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		scroll-behavior: smooth;
		position: relative;
		padding: 0;
		overflow: hidden;
		// border: 2px solid violet;

		background: #000000; /* fallback for old browsers */
		background: -webkit-linear-gradient(
			to right,
			#434343,
			#000000
		); /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(
			to right,
			#434343,
			#000000
		); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

		header {
			margin: 0;
			padding: 0;
			width: 100%;
		}

		main {
			margin: 0;
			padding: 0;
			width: 100%;
			// border: 2px solid blue;
		}

		footer {
			margin: 0;
			padding: 0;
			width: 100%;
			// border: 2px solid green;
		}
	}

	@include mobile {
	}

	@include desktop {
	}
}
