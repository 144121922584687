.general__container_gallery {
  position: relative;
  width: 98%;
  margin: 0 auto;
  margin-top: 5rem;
  text-align: center;
  background-color: $Dark;
  overflow: hidden;

  h2{
    @include font-size(20px, 45px);
    color: $mainColor;
  }

  .title {
    @include font-size(28px, 60px);
    width: 100%;
    font-weight: 700;
    line-height: 1.8em;
    color: $Light;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 0;
    text-decoration: none;
    // border: 2px solid white;

    span {
      color: $mainColor;
    }
  }

  .title-photos {
    @include font-size(28px, 60px);
    width: 100%;
    font-weight: 700;
    line-height: 1.8em;
    color: $Light;
    text-align: center;
    margin-bottom: 0;
    text-decoration: none;
    // border: 2px solid white;

    span {
      color: $mainColor;
    }
  }

  .filter-container{
    @include font-size(22px, 50px);
    color: $Light;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1em;
    // border: 1px solid red;

    input{
      padding: 0.3em;
    }

    p{
      margin: 0;

      b{
        font-size: 1.2em;
        color: $mainColor;
        margin-left: 0.3em;
        padding: 0 1em;
        background-color: $Light;
        border: 1px solid $MedDark;
      }
    }

   .btn{
      color: $Light;
      font-weight: bold;
      background-color: $mainColor;
      text-transform: uppercase;
    }
  }

  .left,
  .right {
    position: absolute;
    top: 1rem;
    padding: 1rem 1.3rem;
    // border: 2px solid;

    svg {
      @include size(20px, 40px);
      fill: $Light;
    }
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }

  footer {
    margin: auto;
    width: 100%;
  }
}

// FOR SMALL MOBILES
@include mQ(200px, 360px) {
  .gallery {
    .left,
    .right {
      padding: 1rem 0.2rem;
    }
  }
}

// FOR DESKTOP
@include mQ(1200px) {
  .gallery {
    .left,
    .right {
      padding: 1.8rem 3rem;
    }
  }
}
