// font-size( minSize, maxSize)
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,300&display=swap');

body {
	
	h1 {
		@include font-size(28px, 60px);
		font-weight: 700;
		line-height: 1.1em;
	}
	h2 {
		@include font-size(22px, 48px);
		font-weight: 500;
		line-height: 1.2em;
	}
	h3 {
		@include font-size(20px, 38px);
		font-weight: 400;
		line-height: 1.3em;
	}
	h4 {
		@include font-size(18px, 28px);
		font-weight: 400;
		line-height: 1.4em;
	}
	p,
	h5,
	h6 {
		@include font-size(16px, 21px);
		font-weight: 400;
		line-height: 1.5em;
	}
}
