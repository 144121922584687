.component_container_photos {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0;

	.sections-container {
		width: 100%;
		margin: 0;
		padding: 0.1em;
	}

	section {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	section.hidden,
	img.hidden {
		display: none;
	}

	input,
	.settings,
	.photo-container,
	#file {
		width: 500px;
		max-width: 100%;
	}

	.photo-container {
		min-height: 45vh;
		margin: auto;
	}

	p,
	input,
	label {
		font-size: 16px;
		color: $Light;
	}

	#file {
		font-size: 20px;
		margin: auto;
		visibility: hidden;
	}

	.image-btn {
		display: block;
		font-size: 22px;
		width: 500px;
		max-width: 80%;
		margin: auto;
		text-align: center;
		color: white;
		background-color: $mainColor;
		border: 2px solid $Light;
		border-radius: 5px;
		cursor: pointer;
		transition: all 0.5s ease;
		box-shadow: 4px 4px 8px grey;
		z-index: 1000;

		&:hover {
			color: gold;
			box-shadow: 2px 2px 4px grey;
		}
	}

	#file::-webkit-file-upload-button {
		width: 15ch;
		margin-right: 1em;
		color: white;
		background-color: $mainColor;
		border: 2px solid $Light;
		border-radius: 5px;
		cursor: pointer;
		transition: all 0.5s ease;
	}

	#file:hover {
		margin-left: 0.5em;
	}

	img {
		width: 100%;
		max-height: 90vh;
		object-fit: contain;
	}

	.settings {
		font-size: 16px;
		margin: auto;
		margin-top: 10px;
		padding: 0.7em 1em;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border: 2px solid grey;
		gap: 0.7em;
	}

	.slider-container {
		display: flex;
		align-items: center;

		.slider {
			width: 280px;
			max-width: 95%;
			margin: 0 0.5em;
			vertical-align: middle;
			cursor: pointer;
		}

		.slider-button {
			font-size: 20px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1.2em;
			margin: 0;
			padding: 0;
			color: white;
			background-color: DodgerBlue;
			border: none;
			cursor: pointer;

			&:hover {
				box-shadow: none;
				background-color: gold;
			}
		}
	}

	button {
		width: 360px;
		max-width: 45%;
		margin: 0;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 1px;
		padding: 0.3em 0;
		margin-top: 0.6em;
		margin-bottom: 0.8em;
		background-color: transparent;
		border-radius: 5px;
		cursor: pointer;
		box-shadow: 3px 3px 6px grey;
		transition: all 0.5s ease;

		&:hover {
			box-shadow: 1px 1px 2px grey;
		}
	}

	button.set {
		color: $mainColor;
		border: 2px solid $Light;
		margin: 0 5px;
		margin-top: 1em;

		&:hover {
			color: gold;
		}
	}

	button.reset {
		color: red;
		border: 2px solid red;
		margin: 0 5px;
		margin-top: 1em;

		&:hover {
			color: gold;
		}
	}

	a#new {
		display: block;
		text-align: center;
	}
	.selectors,
	.result {
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		width: 100%;
		justify-content: center;
		gap: 1em;
	}

	.option {
		font-weight: bold;
	}

	.result {
		p {
			font-size: 20px;
			margin: 0;
		}
	}

	.dataResized {
		border: 2px solid gold;
		width: 100%;
		padding: 0.3em;

		span {
			color: gold;
		}
	}

	.select {
		color: DodgerBlue;
		font-size: 20px;
		font-weight: bold;
		border: none;
		margin: 0;
		transition-delay: 2s;
	}

	select {
		font-size: 20px;
		width: 12ch;
		padding-left: 0.5em;
		color: $Light;
		background-color: $mainColor;
		border: 2px solid $Light;
		border-radius: 5px;
		cursor: pointer;
		transition: all 0.5s ease;
		box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.3);
	}

	select:hover {
		width: 13ch;
	}

	a {
		text-decoration: none;
		letter-spacing: 1px;
		padding-top: 0.3em;
		padding-bottom: 0.3em;
		padding-left: 1em;
		padding-right: 1em;
		color: white;
		background-color: $mainColor;
		border: 2px solid $Light;
		border-radius: 5px;
		transition: all 0.5s ease;
		box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.3);
	}

	a:hover {
		font-size: 22px;
	}

	.exif-data {
		overflow: hidden;
		white-space: initial;
		width: 100%;
		margin: auto;
		padding: 0.5em;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		border: 2px solid grey;
	}

	.exif-data p {
		font-size: 10px;
		margin: 0.5em 1em;
	}
}

@media only screen and (min-width: 600px) {
	.component_container_photos {
		input .settings,
		.photo-container {
			width: 600px;
			max-width: 100vw;
		}

		.photo-container {
			min-height: 55vh;
		}

		.selectors,
		.result {
			gap: 0.5em;
		}

		select,
		.select {
			font-size: 18px;
		}

		.filters {
			gap: 0.5em;
		}

		.slider-container {
			button,
			.slider {
				width: 560px;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	.component_container_photos {
		input,
		.settings,
		.photo-container {
			width: 760px;
			max-width: 100vw;
		}

		.settings {
			width: 100%;
			gap: 3px;
		}

		.slider-container {
			button,
			.slider {
				width: 660px;
			}
		}

		.exif-data {
			padding: 0.5em;
		}

		.exif-data p {
			font-size: 16px;
			margin: 0 0.8em;
		}
	}
}

@media only screen and (min-width: 1280px) {
	.component_container_photos {
		input,
		.photo-container {
			width: 1000px;
			max-width: 100vw;
		}

		.sections-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
		}

		section.photo {
			width: 60%;
			padding: 5px;
			//border: 2px solid red;
		}

		section.container_settings {
			width: 35%;
			padding: 7px;
			// border: 2px solid red;
		}

		.settings {
			margin-top: 10px;
		}

		select,
		.select {
			font-size: 20px;
		}

		.slider-container {
			button,
			.slider {
				width: 440px;
				max-width: 100%;
			}
		}

		button.set,
		button.reset {
			width: 150px;
		}

		.result {
			gap: 6px;
		}
	}
}
