.home {
	text-align: center;
	overflow: hidden;
	max-width: $maxWidth;

	.container-home {
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: flex-end;
		margin: 0;
		padding: 0;
		background-size: cover;
		background-repeat: no-repeat;
		// background-attachment: fixed;
		transition: 3s;
		// border: 2px solid;

		&__text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			max-width: $maxWidth;
			margin: 0;
			margin-bottom: 23vh;
			padding: 0.5rem 2rem;
			text-align: right;
			background-color: rgba(0, 0, 0, 0.4);

			h1 {
				@include font-size(18px, 45px);
				margin: 0 0.5rem;
				font-weight: 200;
				color: $Light;
			}

			h2 {
				@include font-size(30px, 80px);
				margin: 0 0.5rem;
				font-weight: 700;
				color: $Light;
				// text-shadow: 1px 1px 1px grey;

				span {
					font-weight: 400;
					color: $Light;
					text-shadow: 1px 1px 1px grey;
				}
			}

			p {
				@include font-size(22px, 50px);
				letter-spacing: 1px;
				text-decoration: none;
				margin: 0;
				margin-top: 0.5em;
				margin-bottom: 0.5em;
				padding: 0 1em;
				// width: 11em;
				color: $Light;
				background-color: $mainMDark;
				border-radius: 0.2em;
				cursor: pointer;
				transition: all 0.3s ease;

				&:hover {
					font-weight: bold;
					box-shadow: $btn-shadow;
				}
			}
		}
	}
	.container-about {
		color: $Light;
		margin-top: 2em;
		margin-bottom: 2em;
		padding: 0.5em;

		h2 {
			@include font-size(30px, 58px);
		}

		p {
			@include font-size(18px, 30px);
			font-family: 'Roboto', sans-serif;
			font-weight: 200;
			text-align: left;
		}
	}
}
// FOR TABLETS & DESKTOP
@include mQ(768px) {
	.home {
		.container-home {
			&__text {
				h2 {
					text-shadow: none;
				}
			}
		}
		.container-about {
			padding: 3em;
		}
	}
}
