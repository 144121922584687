.general__container.admin {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	
	header{
		
		.title {
			@include font-size(24px, 50px);
			width: 100%;
			margin-top: 2em;
			font-weight: 700;
			line-height: 3em;
			color: $Light;
			text-align: center;
			text-transform: uppercase;
		}

	}


	.user {
		color: $Light;
	}

	main{
		display: flex;
		flex-direction: column;
		justify-content: center;

		button{
			width: 50%;
			margin: auto;
			margin-top: 1em;
			font-weight: 700;
		}
		.title {
			@include font-size(20px, 50px);
			width: 100%;
			margin-top: 2em;
			font-weight: 700;
			line-height: 3em;
			color: $Light;
			text-align: center;
		}

		form.exif {
			margin: 1rem auto;
			padding: 1em;
			color: $Light;
			background-color: $Dark;
	
			label {
				@include font-size(18px, 28px);
				margin-right: 1rem;
				width: 300px;
			}
	
			input {
				@include font-size(16px, 24px);
				width: 300px;
			}
	
			button {
				margin-right: 1rem;
			}
		}
	}

	footer {
		color: $Light;
		text-align: center;
		padding: 0.8em 0;
		margin-top: 4em;
		margin-bottom: 2em;

		a {
			@include font-size(16px, 26px);
			margin: 0;
			text-decoration: none;
			cursor: pointer;
			color: $Light;

			&:hover {
				color: darken($mainColor, 10%);
			}
		}
		p {
			@include font-size(14px, 24px);
			margin: 0;
		}
	}
}
// FOR TABLETS & DESKTOP
@include mQ(768px) {
}
