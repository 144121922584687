.photos-to-json {
	width: 100%;
	overflow: hidden;
	margin-bottom: 2em;
	padding: 1em;

	p {
		padding: 1em;
		background-color: $Light;
	}

	.btn__photos {
		margin: 1rem auto;
	}

	footer {
		color: $mainColor;
		background-color: $Dark;
		text-align: center;
		padding: 0.8em 0;
		margin-bottom: 2px;

		a {
			margin: 0;
			text-decoration: none;
			cursor: pointer;
			color: $mainColor;

			&:hover {
				color: darken($mainColor, 10%);
			}
		}
		p {
			margin: 0;
		}
	}
}
