form {
  position: relative;
  width: 500px;
  max-width: 98%;
  margin: auto;
  padding: 0.8em;
  background-color: $Light;
  border-radius: 0.3em;
  border: 1px solid $ExtraLight;
  box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
  z-index: 20;

  h2 {
    text-align: center;
    color: $mainColor;
    margin-top: 1em;
    margin-bottom: 0.5em;
    color: $Light;
    background-color: $mainColor;
    border: 1px solid $MedLight;
  }

  span.error {
    display: block;
    text-align: center;
    // text-transform: uppercase;
    font-weight: bold;
    color: red;
    //border: 2px solid red;
  }

  .inline-text {
    display: flex;

    h4 {
      margin-right: 0.5em;
    }
  }

  img.img-form {
    max-width: 300px;
    max-height: 300px;
    display: block;
    margin: auto;
    margin-bottom: 0.5em;
  }

  .form-item-data {
    padding: 0.5em;
    white-space: initial;
    width: 100%;
    margin: auto;
    //padding: 0.5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: 1px solid $MedLight;

    p {
      margin: 0 0.8em;
      @include font-size(12px, 16px);
      text-transform: uppercase;
    }
  }

  .form-item {
    overflow: hidden;
    margin: 0.5em 0;
    color: $Dark;

    label,
    input,
    p {
      @include font-size(13px, 17px);
    }
    input {
      width: 100%;
    }
    select {
      @include font-size(12px, 16px);
    }
    .error {
      margin: 0;
      @include font-size(12px, 14px);
      font-style: italic;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      color: $Danger;
    }
    .inputError {
      border: 1px solid $Danger;
    }
    .server-error {
      @include font-size(13px, 18px);
      text-align: center;
    }
    input {
      border: 2px solid $ExtraLight;
      box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
    }
    textarea {
      @include font-size(16px, 18px);
      margin: auto;
      margin-top: 0.5em;
      width: 100%;
      height: 80%;
      outline: none;
      resize: none;
      border: 2px solid $ExtraLight;
      border-radius: 3px;
      box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
    }
  }

  .checkbox {
    margin: 1em;

    h3 {
      @include font-size(16px, 24px);
    }
    .checkbox-item {
      display: none;
    }
    label {
      @include font-size(14px, 20px);
      margin-bottom: 0.3em;
      cursor: pointer;
      margin-right: 1em;
      display: inline-flex;

      span {
        background-color: $Light;
        border: 1px solid $mainColor;
        border-radius: 3px;
        color: transparent;
        margin-right: 0.5em;
        height: 1em;
      }
    }
    .checkbox-item:checked + label {
      span {
        color: $mainColor;
        background-color: transparent;
        border: transparent;
      }
    }
  }

  .form-textarea {
    display: flex;
    position: relative;

    p {
      @include font-size(22px, 30px);
      font-family: italic;

      &.form-textarea-botom {
        position: absolute;
        right: -0.5em;
        bottom: -0.6em;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2em 0 1.2em 0;
    width: 100%;
    // border: 1px solid red;

    .btn-register {
      color: $mainColor;
      background: $Light;
    }
  }

  .icons-container {
    display: flex;
    width: 100%;
    margin-top: 0.8em;
    margin-left: 2em;
  }
}
