.pagination-controls {
	display: grid;
	max-width: 500px;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
	padding: 0.5rem;
	margin: 2rem auto;
	justify-items: center;
	align-items: center;
	/* border: 2px solid red; */
}

.pagination-controls span {
	@include font-size(16px, 22px);
	color: white;
}

.pagination-controls button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.pagination-controls .btn {
	color: black;
	padding: 0.3em 0.5em;
}

/* Desktops */
@media only screen and (min-width: 1024px) {
	.pagination-controls span {
		@include font-size(24px, 30px);
	}
}
