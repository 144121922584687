.general__container_photos {
  position: relative;
  margin: 0;
  margin-top: 4rem;
  text-align: center;
  background-color: $Dark;
  overflow: hidden;

  .title {
    @include font-size(28px, 60px);
    width: 100%;
    font-weight: 700;
    line-height: 1.8em;
    color: $Light;
    text-align: center;
    margin: 0;
    text-decoration: none;
    // border: 2px solid white;

    span {
      color: $mainColor;
    }
  }

  .Loading{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: $mainColor;

		&:nth-child(2) , h2{
			margin: auto;
		}
	}

  footer {
    margin: auto;
    width: 100%;
  }
}

// FOR SMALL MOBILES
@include mQ(200px, 360px) {
  //
}

// FOR DESKTOP
@include mQ(1200px) {
  //
}
