.general__container.projects-container {
	position: relative;
	overflow: hidden;

	h1 {
		text-align: center;
		margin-top: 2em;
		color: $Light;
		// border: 2px solid;
		// box-shadow: 0 3px 6px rgba(250, 250, 250, 0.5);
		b{
			color: $mainColor;
		}
	}

	.Loading{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: $mainColor;

		&:nth-child(2) , h2{
			margin: auto;
		}
	}

	.crud-title{
		@include font-size(30px, 48px);
	}

	.search-container{
		display: flex;
		justify-content: center;
		width: 100%;

		input{
			@include font-size(20px, 28px);
		}

		button{
			display: flex;
			width: 3em;
			margin: 0 1em;
			align-items: center;
			justify-content: center;
		}
	}

	.projects-wrapper {
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		> * {
			@include size(205px, 400px, 2, 1);
		}

		.project {
			display: grid;
			place-items: center;
			background-repeat: no-repeat;
			background-size: cover;

			&:hover {
				border: 5px solid $Light;
			}

			h3 {
				@include font-size(35px, 60px);
				color: $Light;
				cursor: pointer;
				text-shadow: 2px 2px 2px grey;
				transition: transform 1s;

				&:hover {
					transform: scale(1.5);
				}
			}

			.link {
				text-decoration: none;
			}
		}
	}
}

@include mQ(200px, 400px) {
	.general__container.projects-container {
		.projects-wrapper {
			> * {
				@include size(185px, 400px, 2, 1);
			}
		}
	}
}

@include mQ(1024px) {
	.general__container.projects-container {
		.projects-wrapper {
			> * {
				@include size(200px, 400px, 1, 1);
			}
		}
	}
}
