.general__container.cookies {
	background: $Light;
	color: $Dark;
	padding: 0 0.5em;

	main {
		overflow-y: hidden;

		h1 {
			color: $mainMDark;
			display: flex;
			justify-content: center;
			margin-top: 3em;
		}
		p {
			text-align: justify;
		}
	}
}

// FOR TABLETS & DESKTOPS
@include mQ(768px) {
	.general__container.cookies {
		padding: 0 2em;

		h1 {
			margin-top: 1em;
		}
	}
}
