.crud-title{
    color: $mainColor;
}

.input-project-container{
    position: relative;
    width: 100%;
    max-width: 800px;
    color: $Dark;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    margin: 1em auto;
    border: 2px solid $Light;

    

    .input-project-img{
        display: grid;
		place-items: center;
        width: 39%;
        height: 100%;
        min-height: 300px;
        margin: 0;
        overflow: hidden;
        border: 2px solid $Dark;
        
        background-repeat: no-repeat;
		background-size: cover;
        background-position: center center; 

        .form-item{
           text-align: center;

            .image-container{
                width: 100%;

                img {
                    width: 100%
                }   
            }
            #type-file{
                width: 90%;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0.5em auto;
            }
        }

        .add-img{
            @include font-size(16px, 24px);
            color: $Light;
            background-color: $mainColor;
            font-weight: bold;
            letter-spacing: 1px;
            padding: 0.3em 0.5em;
        }
    }

    .input-project-data{
        display: block;
        color: $Dark;
        width: 60%;
        margin: 0;
        padding: 0.5em;
        border: 2px solid $Dark;
        overflow: hidden;
        white-space: nowrap;

        label{
            @include font-size(14px, 22px);
            margin: 0;
            margin-bottom: 0.3em;
        }
        input{
            @include font-size(16px, 24px);
            width: 100%;
            margin-bottom: 0.3em;
        }

        .input-btn-container{
            display: flex;
            gap: 0.3em;

            button{
                padding: 0.3em 0.5em;
            }
            .submit {
                color: $Light;
                background-color: $mainColor;
                font-weight: bold;
                letter-spacing: 1px;
            }
            .reset{
                color: $Light;
                background-color: red;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
    }

}

@media (max-width: 600px){
    .input-project-container{
        flex-direction: column;

        .input-project-img{
            width: 100%

        }

        .input-project-data{
            width: 100%;
        }
    }
}
