.image-item-container {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.5s;

	&:hover {
		transform: scale(1.05);
		border: 5px solid $Light;
	}
}

@include mQ(998px) {
	.image-item-container {
		margin-bottom: 0;
	}
}
