.icon {
	display: flex;
	height: 4vh;

	svg {
		@include size(18px, 28px);
		fill: $Light;
		margin: auto;

		&:hover,
		:focus {
			fill: $mainColor;
		}
	}
}

.icon-close {
	position: absolute;
	height: 5vh;
	top: 0.5em;
	right: 0.5em;

	svg {
		@include size(22px, 28px);
		fill: $mainColor;
		margin: auto;
	}
}

.icons-quote,
.icons-like {
	display: flex;
	height: 4vh;
	border: none;
	margin-right: 2em;
	background: $Light;
	cursor: pointer;

	svg {
		@include size(20px, 24px);
		fill: $MedLight;
		background: $Light;
		margin: 0;

		&:hover,
		&:focus {
			fill: $Dark;
		}
	}
}

.icons-social {
	margin: 0.2em 0.5em;
	svg {
		@include size(24px, 28px);
		fill: $MedLight;
		background: $Light;
		margin: auto;

		&:hover,
		&:focus {
			fill: $Dark;
		}
	}
}

.icon-github {
	svg {
		fill: $Github;
	}
}

.icon-instagram {
	svg {
		fill: $Instagram;
	}
}

.icon-twitter {
	svg {
		fill: $Twitter;
	}
}

.icon-linkedin {
	svg {
		fill: $Linkedin;
	}
}
