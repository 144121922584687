.crud-title{
    color: $mainColor;
}

.card-video-container{
    position: relative;
    width: 90%;
    max-width: 900px;
    color: $Dark;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 1em auto;
    padding: 0.3em 2em;
    background-color: $Light;
    border-radius: 0.5em;

    .card-video-data{
        display: block;
        color: $Dark;
        max-width: 100%;
        margin: 0;
        padding: 0.5em;
        overflow: hidden;
        white-space: nowrap;
        
        p{
            @include font-size(14px, 26px);
            color: $MedDark;
            margin: 0;
            margin-bottom: 0.3em;

            b{
                @include font-size(16px, 30px);
                color: $Dark;
            }
        }

        .number{
            position: absolute;
            left: 0.7em;
            top: 50%;
            transform: translateY(-50%);
            color: $mainColor;
        }
    }
    .buttons-container{
        .btn{
            width: 10em;
            margin: 0.3em 0.5em;
        }
    }
}