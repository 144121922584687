.general__container_videos {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;
    text-align: center;
    background-color: $Dark;
    overflow: hidden;
  
    h2{
      @include font-size(20px, 45px);
      color: $mainColor;
    }

    .title {
        @include font-size(28px, 60px);
        width: 100%;
        font-weight: 700;
        line-height: 1.8em;
        color: $Light;
        text-align: center;
        margin-bottom: 1em;
        text-decoration: none;
        // border: 2px solid white;
    
        b {
          color: $mainColor;
        }
      }

      .Loading{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: $mainColor;
    
        &:nth-child(2) , h2{
          margin: auto;
        }
      }
    
      .filter-container{
        @include font-size(22px, 50px);
        color: $Light;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5em;
        margin-bottom: 1em;
        // border: 1px solid red;
    
        input{
          padding: 0.3em;
        }
    
        p{
          margin: 0;
    
          b{
            font-size: 1.2em;
            color: $mainColor;
            margin-left: 0.3em;
            padding: 0 1em;
            background-color: $Light;
            border: 1px solid $MedDark;
          }
        }
    
       .btn{
          color: $Light;
          font-weight: bold;
          background-color: $mainColor;
          text-transform: uppercase;
        }
      }

      .videos-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // border: 2px solid red;

        iframe{
            max-width: 605px;
            min-width: 325px;
            width: 100%;
            height: 705px;
            border: none;
        }
    }
}

// FOR TABLETS & DESKTOP
@include mQ(768px) {
    .general__container_videos{
      
        .videos-container{
            gap: 2em;
            
            iframe{
                max-width: 605px;
                min-width: 325px;
                width: auto;
                height: 705px;
            }
        }
    }
  }