.crud-title{
    color: $mainColor;
}

.input-video-container{
    position: relative;
    width: 100%;
    max-width: 800px;
    color: $Dark;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    margin: 1em auto;
    border: 2px solid $Light;

    .input-video-data{
        display: block;
        color: $Dark;
        width: 100%;
        margin: 0;
        padding: 0.5em;
        border: 2px solid $Dark;
        overflow: hidden;
        white-space: nowrap;

        label{
            @include font-size(14px, 22px);
            margin: 0;
            margin-bottom: 0.3em;
        }
        input{
            @include font-size(16px, 24px);
            width: 95%;
            margin-bottom: 0.3em;
        }
    }

    .input-btn-container{
        display: flex;
        gap: 0.3em;

        button{
            padding: 0.3em 0.5em;
        }
        .submit {
            color: $Light;
            background-color: $mainColor;
            font-weight: bold;
            letter-spacing: 1px;
        }
        .reset{
            color: $Light;
            background-color: red;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }
}